@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  background-color: #dee9f2;
}

.background-gradient {
  background-image: linear-gradient(
    135deg,
    var(--primary-base-gradiant-color),
    var(--secondaty-base-gradiant-color)
  );
}

.stream-background-gradient {
  background-image: linear-gradient(135deg, #0c1c44bd, #4a7190ad);
}

.pulse {
  position: relative;
  height: 112px;
  width: 112px;
}

.pulse:before,
.pulse:after {
  border-radius: 50%;
  content: "";
  position: absolute;
}

.pulse:before {
  background: #ffffff;
  height: 22.4px;
  width: 22.4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pulse:after {
  animation: pulse-t3pv1p 1.2000000000000002s infinite;
  border: 11.2px solid #ffffff;
  height: 100%;
  width: 100%;
}

@keyframes pulse-t3pv1p {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(1);
  }
}

.small_pulse {
  position: relative;
  height: 40px;
  width: 40px;
}

.small_pulse:before,
.small_pulse:after {
  border-radius: 50%;
  content: "";
  position: absolute;
}

.small_pulse:before {
  background: #ffffff;
  height: 12px;
  width: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.small_pulse:after {
  animation: pulse-t3pv1p 2s infinite;
  border: 11.2px solid #ffffff;
  height: 100%;
  width: 100%;
  left: 0;
}

:root {
  --primary-color: var(--primary-base-color);
  --button-primary-color: var(--primary-base-color);
  --secondary-color: #fff;
  --primary-gradient: linear-gradient(93deg, #05f18f -3.22%, #078a54 103.49%);
  --light-blue: #eeebf3;
  --primary-base-color: #002b6f;
  --primary-base-color-darken: #002155;
  --primary-accent-color: #002b6f15;
  --primary-base-opacity30: #002b6f30;
  --primary-base-opacity50: #002b6f50;
  --primary-base-gradiant-color: #0c1c44;
  --secondaty-base-gradiant-color: #4a7190;
}

.primary-color {
  background: var(--primary-color);
}
.btn-primary {
  background-color: var(--primary-color);
}
.btn-primary-color {
  background: var(--primary-color);
}

* {
  &::selection {
    background-color: #4a719091;
  }
}

// .card {
//   width: 300px; /* Adjust as needed */
//   border-radius: 10px;
//   overflow: hidden;
//   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
//   margin: 20px;
//   position: relative;
// }

.image-container {
  height: 200px; /* Adjust as needed */
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  position: absolute;
  display: flex;
  gap: 10px;
}

.play-button,
.pause-button {
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 20px; /* Adjust button size */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.description {
  padding: 10px;
  text-align: center;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* White background with slight transparency */
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5em;
  font-weight: bold;
}

h2 {
  font-size: 2em;
  font-weight: bold;
}

h3 {
  font-size: 1.75em;
  font-weight: bold;
}

h4 {
  font-size: 1.5em;
  font-weight: bold;
}

h5 {
  font-size: 1.25em;
  font-weight: bold;
}

h6 {
  font-size: 1em;
  font-weight: bold;
}

p {
  font-size: 1em;
  font-weight: normal;
}

.CustomDrawer {
  .CustomDrawer--Content {
    &::-webkit-scrollbar {
      width: 4px; /* Width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bbb6b6; /* Color of the scrollbar handle */
      border-radius: 4px; /* Border radius of the scrollbar handle */
    }
  }
}
.overflow-y-auto {
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d1cece; /* Color of the scrollbar handle */
    border-radius: 4px; /* Border radius of the scrollbar handle */
  }
}

.thank-you-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9fafb;
  color: #333;
}

.thank-you-content {
  text-align: center;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.thank-you-content h1 {
  color: var(--primary-base-color);
}

.animation-container {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark {
  width: 60px;
  height: 60px;
  stroke: var(--primary-base-color);
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  animation: checkmark 0.5s ease-in-out forwards;
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 50;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.primary-btn,
.secondary-btn {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.primary-btn {
  background-color: var(--primary-base-color);
  color: #fff;
  border: none;
}

.secondary-btn {
  background-color: #e0e0e0;
  color: #333;
  border: none;
  display: flex;
  width: 150px;
  align-items: center;
}

.description_normal {
  position: absolute;
  bottom: 0px;
  padding: 10px;
  height: auto;
  background-image: linear-gradient(#4a7190, #002155);
  width: 100%;
}







// START: TEMPLATE
@blue: #26bbf0;
@green: #c2d5a0;
@red: #cf6a6d;
@yellow: #ffbc0d;

@black: #343536;
@black-2: #424950;
@grey: #89949b;
@grey-2: #9aa8b1;
@light-grey: #c4c9cd ;
@light-grey-2: #dbdfe1;
@white: #f3f4f5;

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: "proxima-nova-soft", sans-serif;
  -webkit-user-select: none;
  overflow: hidden;
  
  .vertical-centered-box {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    &:after {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    }
    .content {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      font-size: 0;
    }
  }
}

* {
  transition: all .3s;
}
// END: TEMPLATE


// body {
//   background: mix(#002155, #002025, 70%);
// }

#prism-logo {
  path {
    // fill: #20293b;
    // stroke: rgba(255,255,255,1);
    // stroke-width: 1px;
  }
}

.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.1);
  margin-left: -60px;
  margin-top: -60px;
  // .animation(fade 1.2s infinite ease-in-out);
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(top, rgba(0,0,0,1), rgba(0,0,0,0));
  animation: rotate 1.2s infinite linear;
  .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.5);
  }
}

#particles-background, #particles-foreground {
  left: -51%;
  top: -51%;
  width: 202%;
  height: 202%;
  transform: scale3d(.5, .5, 1);
}

#particles-background {
  background: mix(#002155, #002025, 70%);
  background-image: -moz-linear-gradient(45deg, #002155 2%, #002025 100%);
  background-image: -webkit-linear-gradient(45deg, #002155 2%, #002025 100%);
  background-image: linear-gradient(45deg, #002155 2%, #002025 100%);
}

@keyframes rotate { 0% { transform: rotate(0deg);} 100% { transform: rotate(360deg);}}

@keyframes fade { 0% { opacity: 1;} 50% { opacity: 0.25;}}

@keyframes fade-in { 0% { opacity: 0;} 100% { opacity: 1;}}


.log-out-button {
  position: absolute;
  right: 35px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  background-color: var(--primary-base-color-darken);
  border-radius: 5px;
  color: #ffffff;
}